import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
export default [
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index",
    path: indexAoBMx6PnC1Meta?.path ?? "/",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  }
]