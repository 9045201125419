export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"application-name","content":"MVE - Yachting and Cybersecurity Monaco"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"keywords","content":"MVE, cyber-security, yachting, yacht tech, smart-home, Monaco, blockchain, cloud storage, server, digital security, smart yacht, audit, certification, hosting, sovereign hosting, maritime cyber solutions"},{"name":"robots","content":"index, follow"},{"name":"language","content":"en"},{"http-equiv":"Cache-Control","content":"no-cache, no-store, must-revalidate"},{"http-equiv":"Expires","content":"0"},{"http-equiv":"X-UA-Compatible","content":"IE=edge"},{"property":"og:title","content":"MVE - Yachting and Cybersecurity Monaco"},{"property":"description","content":"Discover MVE, Monaco's premier yacht cyber-security expert. We specialize in high-end digital security, cloud storage, smart home systems, and blockchain. Ensuring safety on sea and online."},{"property":"og:description","content":"Discover MVE, Monaco's premier yacht cyber-security expert. We specialize in high-end digital security, cloud storage, smart home systems, and blockchain. Ensuring safety on sea and online."},{"property":"og:type","content":"website"},{"property":"og:url","content":"https://yachting.mve.mc"},{"property":"og:image","content":"https://yachting.mve.mc/assets/logo.webp"},{"property":"og:locale","content":"en_US"},{"property":"og:site_name","content":"MVE - Yachting and Cybersecurity Monaco"}],"link":[],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"},"title":"MVE","charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'